import "./App.css";
import { useAppSelector } from "./hooks/reduxHooks";
import AppRoutes from "./routes/AppRoutes";

function App() {
  const userRole = useAppSelector((state) => state.user.userRole);
  console.log(userRole, "userRole");

  return <AppRoutes />;
}

export default App;
