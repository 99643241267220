import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { loadingBarReducer } from "react-redux-loading-bar";
import userReducer from "../features/user/userSlice";
import generalReducer from "../features/general/generalSlice";
import superAdminReducer from "../features/super-admins/superAdminSlice";
import ngoGroupReducer from "../features/ngo/ngoSlice";

const userPersistConfig = {
  key: "user",
  storage: storage,
  whitelist: ["isAuthenticated", "userDetails", "userRole"], // Specific fields to persist
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);

const rootReducer = combineReducers({
  user: persistedUserReducer,
  general: generalReducer,
  superAdmin: superAdminReducer,
  ngoGroup: ngoGroupReducer,
  // loadingBar: loadingBarReducer,
});

export default rootReducer;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
        ignoredActionPaths: ["register", "rehydrate"],
        ignoredPaths: ["some.path.not.to.be.serialized"],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
