import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import { toast } from "react-hot-toast";
import axios from "axios";

interface AMYSaysData {
  id: number;
  country: string;
  state: string;
  city: string;
  categoryId: number;
  message: string;
}
interface GeneralState {
  AMYSaysData: AMYSaysData;
  categories: any[];
  sideMenu: any[];
  searchQuery: string;
}

interface categoriesType {
  id: number;
  category: string;
}

const initialState: GeneralState = {
  AMYSaysData: {
    id: 0,
    country: "",
    state: "",
    city: "",
    categoryId: 0,
    message: "",
  },
  categories: [],
  sideMenu: [],
  searchQuery: "",
};

interface createNgoPayload {
  name: string;
  address: string;
  contactPerson: string;
  emailId: string;
  phoneNumber: string;
  latitude: string;
  longitude: string;
}

export const submitAMYSaysData = createAsyncThunk(
  "general/submitAMYSaysData",
  async (data: AMYSaysData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/amy-messages/admin-gen/card",
        data
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "general/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/unauthorized/category/subcategories"
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSideMenu = createAsyncThunk(
  "general/fetchSideMenu",
  async (userId: number | null, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/left-menu-for-user/${userId}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBlogs = createAsyncThunk<
  any,
  { page: number; limit: number },
  { rejectValue: any }
>("general/fetchBlogs", async ({ page, limit }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(
      `/admin/organizations?limit=${page}&page=${limit}`
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      return rejectWithValue({
        name: error.name,
        message: "Network error or bad request, no response object.",
      });
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchCategory = createAsyncThunk<
  categoriesType[],
  void,
  { rejectValue: string }
>("locationMaster/fetchCategories", async () => {
  try {
    const response = await axiosInstance.get<categoriesType[]>(
      "/category-masters"
    );
    return response.data;
  } catch (error) {
    throw "An error occurred";
  }
});

export const createNgo = createAsyncThunk<
  number,
  createNgoPayload,
  {
    rejectValue: string;
  }
>("Ngo/create", async (createNgoData: createNgoPayload, thunkAPI) => {
  try {
    const response = await axiosInstance.post<number>("/ngo", createNgoData);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue("Failed to create NGO");
  }
});

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setAMYSaysData(state, action: PayloadAction<AMYSaysData>) {
      state.AMYSaysData = action.payload;
    },
    setSliceSearchQuery(state, action: PayloadAction<any>) {
      console.log(action.payload);
      state.searchQuery = action.payload;
    },
    clearAMYSaysData(state) {
      state.AMYSaysData = initialState.AMYSaysData;
    },
    clearCategories(state) {
      state.categories = initialState.categories;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitAMYSaysData.fulfilled, (state, action) => {
        toast.success(action.payload?.message);
        state.AMYSaysData = action.payload;
      })
      .addCase(submitAMYSaysData.pending, (state) => {
        console.log("Pending Case Hit");
      })
      .addCase(submitAMYSaysData.rejected, (state, action) => {
        console.log("Rejected Case Hit", action.payload);
      })
      .addCase(fetchCategories.pending, (state) => {})
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        toast.error("Failed to load categories");
      })
      .addCase(fetchSideMenu.pending, (state) => {})
      .addCase(fetchSideMenu.fulfilled, (state, action) => {
        state.sideMenu = action.payload?.data;
      })
      .addCase(fetchSideMenu.rejected, (state, action) => {
        // toast.error("Failed to load side menu");
      })
      .addDefaultCase((state, action) => {
        console.log("Unhandled action type: ", action.type);
      });
  },
});

export const {
  setAMYSaysData,
  clearAMYSaysData,
  clearCategories,
  setSliceSearchQuery,
} = generalSlice.actions;

export default generalSlice.reducer;
