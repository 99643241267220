import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const InviteSupportIcon = createSvgIcon(
  <svg
    id="Слой_2"
    data-name="Слой 2"
    xmlns="http://www.w3.org/2000/svg"
    width="17.922"
    height="18.336"
    viewBox="0 0 17.922 18.336"
  >
    <path
      id="Path_4645"
      data-name="Path 4645"
      d="M16.507,15.862l-.459-2.293,1.834-.917V10.36l-2.293.459L13.756,8.984H11.88a2.748,2.748,0,0,1-5.42,0H3.668L2.751,9.9l.917.459v1.376H1.834L0,14.945,1.834,16.32v1.963l1.834.788v1.834l1.376,1.376,3.668.459.459-1.834h1.376s4.127,2.384,4.127,2.293,1.834-1.834,1.834-1.834l-1.365-.927,2.781-3.26Z"
      transform="translate(0 -4.865)"
      fill="#798498"
    />
    <path
      id="Path_4646"
      data-name="Path 4646"
      d="M15.668,0A3.668,3.668,0,0,0,12,3.668c0,2.027,3.668,6.87,3.668,6.87s3.668-4.844,3.668-6.87A3.668,3.668,0,0,0,15.668,0Zm0,5.495A1.834,1.834,0,1,1,17.5,3.661,1.834,1.834,0,0,1,15.668,5.495Z"
      transform="translate(-6.498 0)"
      fill="#676e7b"
    />
    <circle
      id="Ellipse_283"
      data-name="Ellipse 283"
      cx="0.917"
      cy="0.917"
      r="0.917"
      transform="translate(4.585 10.539)"
      fill="#798498"
    />
  </svg>,
  "InviteSupportIcon"
);

export default InviteSupportIcon;
