import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import { toast } from "react-hot-toast";

interface Props {
  ngosList: any;
  organizationDetail: any;
  organizationMembers: any[];
  blogList: any;
  FlaggedList: any;
  pendingInvitations: any[];
}

interface FetchNgosListParams {
  limit?: number;
  page?: number;
}

interface FetchFlaggedListParams {
  limit?: number;
  page?: number;
}

interface InviteMembersParams {
  organizationId: number | null;
  emailAddresses: any;
}

interface FetchFlaggedPerDataArg {
  id: number;
  postType: string;
  postId: number;
}

const initialState: Props = {
  ngosList: {},
  organizationDetail: {},
  organizationMembers: [],
  blogList: {},
  FlaggedList: {},
  pendingInvitations: [],
};

export const fetchFlaggedList = createAsyncThunk(
  "ngo/fetchFlaggedList",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const url = `/reported-posts?size=15&page=${page}`;
      const response = await axiosInstance.get(url);
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchNgosList = createAsyncThunk(
  "ngo/fetchNgosList",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const url = `/admin-organizations?size=15&page=${page}`;
      const response = await axiosInstance.get(url);
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBlogsList = createAsyncThunk(
  "general/fetchBlogsList",
  async (
    { currentPage, userId }: { currentPage: number; userId: number | null },
    { rejectWithValue }
  ) => {
    const page = currentPage - 1;
    try {
      const url = `/admin-blogs?userId=${userId}&page=${page}&size=10&sort=Id`;
      const response = await axiosInstance.get(url);
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFlaggedPerData = createAsyncThunk(
  "ngo/fetchFlaggedPerData",
  async (
    { id, postType, postId }: FetchFlaggedPerDataArg,
    { rejectWithValue }
  ) => {
    try {
      const url = `/reported-posts/reporters/type/${id}?Id=${postId}&reportedPostType=${postType}`;
      const response = await axiosInstance.get(url);
      console.log("response", response);
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        // Network error or other error where error response is not available
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      // Handle HTTP error responses
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrganization = createAsyncThunk(
  "ngo/fetchOrganization",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/admin-organization/${id}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  "ngo/deleteOrganization",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/organizations/${id}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyOrganization = createAsyncThunk(
  "ngo/verifyOrganization",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/admin-organization/${id}/verify`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const requestMoreDocs = createAsyncThunk(
  "ngo/requestMoreDocs",
  async (
    data: { organizationId: number | null; message: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`/request-more-docs`, data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchOrganizationMembers = createAsyncThunk(
  "ngo/fetchOrganizationMembers",
  async (orgId: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/organization/${orgId}/accepted-users`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPendingInvitations = createAsyncThunk(
  "ngo/fetchPendingInvitations",
  async (orgId: number | null, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/organization-invitees/${orgId}/pending`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteMembers = createAsyncThunk(
  "ngo/inviteMembers",
  async (
    { organizationId, emailAddresses }: InviteMembersParams,
    { rejectWithValue }
  ) => {
    console.log(
      organizationId,
      emailAddresses,
      "organizationId, emailAddresses"
    );
    try {
      const response = await axiosInstance.post(
        `/organization/invite-members`,
        { organizationId, emailAddresses }
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removePendingInvitee = createAsyncThunk(
  "ngo/removePendingInvitee",
  async (
    { orgId, email }: { orgId: number | null; email: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.delete(
        `/organization/${orgId}/remove-pending-invitee/${email}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserRole = createAsyncThunk(
  "organization/updateUserRole",
  async (
    {
      organizationId,
      userId,
      memberRole,
    }: {
      organizationId: number | null;
      userId: number | null;
      memberRole: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axiosInstance.put(`/organization-user-role`, {
        organizationId,
        userId,
        memberRole,
      });
      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data.title, {
        duration: 1000,
      });
      return thunkAPI.rejectWithValue(
        error.response?.data?.title || "Failed to update user role: "
      );
    }
  }
);

export const removeAcceptedUser = createAsyncThunk(
  "organization/removeAcceptedUser",
  async (
    { orgId, userId }: { orgId: number | null; userId: number | null },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.delete(
        `/organization/${orgId}/remove-accepted-user/${userId}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleUploadDocuments = createAsyncThunk(
  "ngo/uploadDocuments",
  async (
    { documents, orgId }: { documents: any[]; orgId: number | null },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/upload-documents?organizationId=${orgId}`,
        {
          documents,
        }
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const ngoSlice = createSlice({
  name: "ngoGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNgosList.pending, (state) => {})
      .addCase(fetchNgosList.fulfilled, (state, action) => {
        state.ngosList = action.payload;
      })
      .addCase(fetchNgosList.rejected, (state, action) => {
        toast.error("Failed to load organizations");
      })
      .addCase(fetchBlogsList.pending, (state) => {})
      .addCase(fetchBlogsList.fulfilled, (state, action) => {
        state.blogList = action.payload;
      })
      .addCase(fetchBlogsList.rejected, (state, action) => {
        toast.error("Failed to load blog lists", {
          duration: 1000,
        });
      })
      .addCase(fetchFlaggedList.pending, (state) => {})
      .addCase(fetchFlaggedList.fulfilled, (state, action) => {
        state.FlaggedList = action.payload;
      })
      .addCase(fetchFlaggedList.rejected, (state, action) => {
        toast.error("Failed to load  reported posts", {
          duration: 2000,
        });
      })
      .addCase(fetchOrganization.pending, (state) => {})
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        console.log(action.payload, "all organizations");
        state.organizationDetail = action.payload.data.organization;
      })
      .addCase(fetchOrganization.rejected, (state, action) => {
        toast.error("Failed to load organizations", {
          duration: 1000,
        });
      })

      .addCase(fetchOrganizationMembers.pending, (state) => {})
      .addCase(fetchOrganizationMembers.fulfilled, (state, action) => {
        state.organizationMembers = action.payload;
      })
      .addCase(fetchOrganizationMembers.rejected, (state, action) => {})

      .addCase(fetchPendingInvitations.pending, (state) => {})
      .addCase(fetchPendingInvitations.fulfilled, (state, action) => {
        state.pendingInvitations = action.payload;
      })
      .addCase(fetchPendingInvitations.rejected, (state, action) => {})

      .addCase(deleteOrganization.pending, (state) => {})
      .addCase(deleteOrganization.fulfilled, () => {
        toast.success("Organization deleted successfully");
      })
      .addCase(deleteOrganization.rejected, (state, action: any) => {
        toast.error(action?.payload || "Failed to delete organization");
      })

      .addCase(inviteMembers.pending, (state) => {})
      .addCase(inviteMembers.fulfilled, () => {
        toast.success("Invitations sent successfully");
      })
      .addCase(inviteMembers.rejected, () => {
        toast.error("Failed to send invitation");
      })

      .addCase(removePendingInvitee.pending, (state) => {})
      .addCase(removePendingInvitee.fulfilled, (state, action) => {
        state.pendingInvitations = state.pendingInvitations.filter(
          (invitee) => invitee.email !== action.meta.arg.email
        );
        toast.success("Pending invitee removed successfully");
      })
      .addCase(removePendingInvitee.rejected, (state, action) => {
        toast.error("Failed to remove pending invitee");
      })

      .addCase(updateUserRole.pending, (state) => {})
      .addCase(updateUserRole.fulfilled, (state, action) => {
        toast.success("User role successfully changed!");
        const updatedMemberIndex = state.organizationMembers.findIndex(
          (member) => member.id === action.meta.arg.userId
        );
        if (updatedMemberIndex !== -1) {
          state.organizationMembers[updatedMemberIndex].role =
            action.meta.arg.memberRole;
        }
      })

      .addCase(removeAcceptedUser.pending, (state) => {})
      .addCase(removeAcceptedUser.fulfilled, (state, action) => {
        state.organizationMembers = state.organizationMembers.filter(
          (member) => member.id !== action.meta.arg.userId
        );
        toast.success("User removed successfully");
      })
      .addCase(removeAcceptedUser.rejected, (state, action: any) => {
        toast.error(action?.payload || "Failed to remove user");
      })

      .addCase(handleUploadDocuments.pending, (state) => {})
      .addCase(handleUploadDocuments.fulfilled, (state, action: any) => {
        const documentsLength = action.meta.arg.documents.length;
        if (documentsLength > 0) {
          toast.success(action?.payload?.message);
        } else {
          toast.success(action?.payload?.message);
        }
      })
      .addCase(handleUploadDocuments.rejected, () => {
        toast.error("Failed to update documents");
      })

      .addCase(verifyOrganization.pending, (state) => {})
      .addCase(verifyOrganization.fulfilled, () => {})
      .addCase(verifyOrganization.rejected, () => {
        toast.error("Failed to verify organization", {
          duration: 1000,
        });
      })
      .addCase(requestMoreDocs.pending, (state) => {})
      .addCase(requestMoreDocs.fulfilled, () => {
        toast.success("Request email has been sent");
      })
      .addCase(requestMoreDocs.rejected, () => {
        toast.error("Failed to send request");
      });
  },
});
export const {} = ngoSlice.actions;

export default ngoSlice.reducer;
