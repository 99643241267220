import { Box, useTheme } from "@mui/material";
import LoaderIcon from "./LoaderIcon";

export default function PageLoader() {
  const theme = useTheme();

  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LoaderIcon color={theme.palette.primary.main} size={60} />
    </Box>
  );
}
