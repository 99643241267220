import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { axiosInstance } from "../../api/axios";

interface UserState {
  userDetails: {
    id: number | null;
    firstName: string;
    lastName: string;
    email: string;
    image: string;
  };
  loginLoading: boolean;
  userRole: string;
  isAuthenticated: boolean;
}
interface LoginCredentials {
  email: string;
  password: string;
  rememberMe: boolean;
}

const initialState: UserState = {
  userDetails: {
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    image: "",
  },
  loginLoading: false,
  userRole: "",
  isAuthenticated: false,
};

interface GSocialLoginParams {
  code: string;
}
export const handleLogin = createAsyncThunk(
  "user/login",
  async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/authenticate", credentials);
      return response.data;
    } catch (error: any) {
      localStorage.clear();
      return rejectWithValue(error.response?.data);
    }
  }
);

export const GoogleSocialLogin = createAsyncThunk(
  "authentication/social_login",
  async (params: GSocialLoginParams, { rejectWithValue, dispatch }) => {
    try {
      const result = await axiosInstance.post<any>(
        "/account/socialLogin",
        {
          ...params,
          provider: "GOOGLE",
          platform: "adminweb",
        },
        {
          validateStatus: function (status) {
            return status >= 200 && status < 300;
          },
        }
      );

      if (result.data) {
        return result.data;
      } else {
        throw new Error("Invalid response data");
      }
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data || { message: "An unexpected error occurred" }
      );
    }
  }
);

export const acceptRequest = createAsyncThunk(
  "user/accept",
  async (token: string | null, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/accept?token=${token}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const declineRequest = createAsyncThunk(
  "user/decline",
  async (token: string | null, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/decline?token=${token}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (
      state,
      action: PayloadAction<UserState["userDetails"]>
    ) => {
      state.userDetails = action.payload;
    },
    setUserRole: (state, action: PayloadAction<string>) => {
      state.userRole = action.payload;
    },
    logout: (state) => {
      state.userDetails = initialState.userDetails;
      state.userRole = initialState.userRole;
      state.isAuthenticated = false;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleLogin.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(handleLogin.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action.payload.role);
        state.loginLoading = false;
        if (action.payload.role === "USER") {
          toast.error("Unauthorized access: Admin privileges required.");
        } else if (action.payload.banned === true) {
          toast.error("Unauthorized access: This user has been banned.");
        } else {
          localStorage.setItem(
            "authenticationToken",
            action.payload.jwtToken?.id_token
          );
          state.isAuthenticated = true;
          state.userRole = action.payload.role;
          state.userDetails = {
            id: action.payload.userId,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            image: action.payload.imageUrl,
          };
        }
      })
      .addCase(handleLogin.rejected, (state, action: PayloadAction<any>) => {
        state.isAuthenticated = false;
        state.loginLoading = false;

        if (action.payload?.status === 404) {
          toast.error(action.payload.detail);
        }

        console.error("Login failed:", action.payload?.status);
      })

      .addCase(GoogleSocialLogin.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(
        GoogleSocialLogin.fulfilled,
        (state, action: PayloadAction<any>) => {
          console.log(action, "ACTION F");
          console.log(action.payload?.role);
          state.loginLoading = false;
          if (action?.payload.role === "USER") {
            toast.error("This user doesn't have the privilege");
          } else if (action.payload.banned === true) {
            toast.error("Unauthorized access: This user has been banned.");
          } else {
            localStorage.setItem(
              "authenticationToken",
              action.payload.jwtToken?.id_token
            );
            state.isAuthenticated = true;
            state.userRole = action.payload.role;
            state.userDetails = {
              id: action.payload.userId,
              firstName: action.payload.firstName,
              lastName: action.payload.lastName,
              email: action.payload.email,
              image: action.payload.imageUrl,
            };
          }
        }
      )
      .addCase(
        GoogleSocialLogin.rejected,
        (state, action: PayloadAction<any>) => {
          console.log(action, "ACTION R");
          state.isAuthenticated = false;
          state.loginLoading = false;

          toast.error(action?.payload?.title || "Something went wrong!");
        }
      )
      .addCase(acceptRequest.pending, (state) => {})
      .addCase(acceptRequest.fulfilled, (state, action: PayloadAction<any>) => {
        toast.success("Invitation accepted successfully");
      })
      .addCase(acceptRequest.rejected, (state, action: PayloadAction<any>) => {
        // toast.error("Invitation declined ");
      })
      .addCase(declineRequest.pending, (state) => {})
      .addCase(
        declineRequest.fulfilled,
        (state, action: PayloadAction<any>) => {
          toast.success("Invitation declined successfully");
        }
      )
      .addCase(declineRequest.rejected, (state, action: PayloadAction<any>) => {
        // toast.error("Invitation declined ");
        toast.error("Invitation has been already  accepted");
      });
  },
});

export const { setUserDetails, setUserRole, logout } = userSlice.actions;

export default userSlice.reducer;
