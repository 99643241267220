import React from "react";
import SideMenu from "../components/SideMenu";
import Header from "../components/Header";
import { Outlet } from "react-router-dom";
import DashboardLayout from "./dashboard-layout";

const Layout = () => {
  return (
    <div
      style={{
        background: "#f6f5f1",
        height: "100vh",
      }}
    >
      <DashboardLayout />
    </div>
  );
};

export default Layout;
