import React from "react";
import { createSvgIcon } from "@mui/material/utils";

// Create the SVG icon
const VideoEditorsIcon = createSvgIcon(
  <svg
    id="video-roll-svgrepo-com"
    xmlns="http://www.w3.org/2000/svg"
    width="23.578"
    height="24"
    viewBox="0 0 23.578 24"
  >
    <path
      id="Path_16309"
      data-name="Path 16309"
      d="M11.713,0a11.712,11.712,0,0,1,8.475,19.795c.652,1.951.867,1.361,3.391,1.734v2.441c-3.766.152-3.016-.223-5.381-2.506A11.712,11.712,0,1,1,11.713,0ZM5.4,8.643a3.151,3.151,0,1,1-3.15,3.152A3.152,3.152,0,0,1,5.4,8.643Zm12.783,0a3.151,3.151,0,1,1-3.15,3.152,3.152,3.152,0,0,1,3.15-3.152Zm-6.3-6.521A3.151,3.151,0,1,1,8.729,5.273a3.15,3.15,0,0,1,3.152-3.152ZM11.77,10.1a1.621,1.621,0,1,1-1.621,1.621A1.622,1.622,0,0,1,11.77,10.1Zm.111,5.209a3.151,3.151,0,1,1-3.152,3.152,3.15,3.15,0,0,1,3.152-3.152Z"
      fill="#676e7b"
      fill-rule="evenodd"
    />
  </svg>,
  "VideoEditorsIcon"
);

export default VideoEditorsIcon;
