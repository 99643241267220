import { ComponentType, LazyExoticComponent, lazy } from "react";
import { UserRole } from "./ProtectedRoute";

const LoginPage = lazy(() => import("../pages/login-page"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const SendNotifications = lazy(() => import("../pages/send-notifications"));
const InviteVideoEditors = lazy(() => import("../pages/invite-video-editors"));
const InviteContentModerators = lazy(
  () => import("../pages/invite-content-moderators")
);
const InviteBlogWriters = lazy(() => import("../pages/invite-blog-writers"));
const InviteSupport = lazy(() => import("../pages/invite-support"));
const NgoGroups = lazy(() => import("../pages/ngo-groups"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const InviteAdmins = lazy(() => import("../pages/invite-admins"));
const BlogList = lazy(() => import("../pages/blog-list"));
const FlaggedList = lazy(() => import("../pages/flagged-post"));
const videoRequest = lazy(() => import("../pages/video-requests"));
const InviteHandle = lazy(() => import("../pages/invite-handle"));
const CreateBlog = lazy(() => import("../pages/create-blog"));
const ChatPage = lazy(() => import("../pages/chat-page"));
const EditBlog = lazy(() => import("../pages/edit-blog"));

interface RouteConfig {
  path: string;
  element: LazyExoticComponent<ComponentType<any>>;
  public?: boolean;
  roles?: UserRole[];
}

export const routeConfig: RouteConfig[] = [
  {
    path: "/login",
    element: LoginPage,
    public: true,
  },
  {
    path: "/forgot-password",
    element: ForgotPassword,
    public: true,
  },
  {
    path: "/chat",
    element: ChatPage,
    public: true,
  },
  {
    path: "/invite",
    element: InviteHandle,
    public: true,
  },
  {
    path: "/invite-admins",
    element: InviteAdmins,
    roles: [UserRole.SUPER_ADMIN],
  },
  {
    path: "/dashboard",
    element: Dashboard,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  },

  {
    path: "/send-notifications",
    element: SendNotifications,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  },
  {
    path: "/invite-video-editors",
    element: InviteVideoEditors,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  },
  {
    path: "/invite-content-moderator",
    element: InviteContentModerators,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  },
  {
    path: "/invite-support",
    element: InviteSupport,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  },
  {
    path: "/video-requests",
    element: videoRequest,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.VIDEO_EDITOR],
  },
  {
    path: "/reported-posts",
    element: FlaggedList,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.CONTENT_MODERATOR],
  },
  {
    path: "/invite-blog-writer",
    element: InviteBlogWriters,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN],
  },
  {
    path: "/ngo-groups",
    element: NgoGroups,
    roles: [
      UserRole.SUPER_ADMIN,
      UserRole.ADMIN,
      UserRole.VERIFICATION_SUPPORT,
    ],
  },
  {
    path: "/blogs-list",
    element: BlogList,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.BLOG_WRITER],
  },
  {
    path: "/edit-blog/:id",
    element: EditBlog,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.BLOG_WRITER],
  },
  {
    path: "/create-blog",
    element: CreateBlog,
    roles: [UserRole.SUPER_ADMIN, UserRole.ADMIN, UserRole.BLOG_WRITER],
  },
];

export const publicRoutes = routeConfig.filter((route) => route.public);
export const protectedRoutes = routeConfig.filter(
  (route) => route.roles !== undefined
);
