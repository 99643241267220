import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const InviteContentModerator = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.853"
    height="24"
    viewBox="0 0 18.853 24"
  >
    <g
      id="admin-with-cogwheels-svgrepo-com_2_"
      data-name="admin-with-cogwheels-svgrepo-com (2)"
      transform="translate(-13.766)"
    >
      <path
        id="Path_16310"
        data-name="Path 16310"
        d="M232.926,93.262c-.031-.573-.616-.475-1.187-.475A5.054,5.054,0,0,1,232.926,93.262Z"
        transform="translate(-206.95 -88.089)"
        fill="#676e7b"
      />
      <path
        id="Path_16311"
        data-name="Path 16311"
        d="M90.077,73.928a2.363,2.363,0,0,0-1.465,4.216,5.116,5.116,0,0,1,3.383-3.228A2.365,2.365,0,0,0,90.077,73.928Z"
        transform="translate(-70.204 -70.189)"
        fill="#676e7b"
      />
      <path
        id="Path_16312"
        data-name="Path 16312"
        d="M15.294,7.516a4.916,4.916,0,0,0,.346.831l-.728.728a.5.5,0,0,0,0,.709L16.2,11.068a.5.5,0,0,0,.709,0l.727-.728c.109.058.23.093.343.143,0-.008,0-.017,0-.025a7.466,7.466,0,0,1,.19-1.684,3.166,3.166,0,1,1,4.461-4.216,5.521,5.521,0,0,1,1.789.052,4.779,4.779,0,0,0-.309-.743l.728-.729a.5.5,0,0,0,0-.708L23.551,1.147a.5.5,0,0,0-.708,0l-.73.729a4.691,4.691,0,0,0-.832-.345V.5a.5.5,0,0,0-.5-.5H18.966a.5.5,0,0,0-.5.5V1.53a4.741,4.741,0,0,0-.832.345l-.728-.728a.5.5,0,0,0-.708,0L14.913,2.43a.5.5,0,0,0,0,.709l.728.728A4.793,4.793,0,0,0,15.3,4.7H14.267a.5.5,0,0,0-.5.5V7.015a.5.5,0,0,0,.5.5Z"
        fill="#676e7b"
      />
      <path
        id="Path_16315"
        data-name="Path 16315"
        d="M37.829,331.289a6.542,6.542,0,0,0-5.619-4.4,4.024,4.024,0,0,1-6.243,0,6.542,6.542,0,0,0-5.621,4.4l-.464,1.366a1.288,1.288,0,0,0,1.219,1.7H37.074a1.287,1.287,0,0,0,1.219-1.7Z"
        transform="translate(-5.742 -310.357)"
        fill="#676e7b"
      />
      <path
        id="Path_16316"
        data-name="Path 16316"
        d="M120.842,117.261c0,3.064,1.865,6.352,4.165,6.352s4.165-3.288,4.165-6.352C129.172,110.91,120.842,110.9,120.842,117.261Z"
        transform="translate(-101.661 -106.804)"
        fill="#676e7b"
      />
    </g>
  </svg>,
  "InviteContentModerator"
);

export default InviteContentModerator;
