// src/routes/AppRoutes.tsx
import React, { Suspense, createElement, lazy, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import "rc-pagination/assets/index.css";
import ProtectedRoute from "./ProtectedRoute";
import { Toaster } from "react-hot-toast";
import Layout from "../layouts/Layout";
import PublicRoute from "./PublicRoute";
import PageLoader from "../components/PageLoader";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { fetchSideMenu } from "../features/general/generalSlice";
import { useAuth } from "../hooks/useAuth";
import { protectedRoutes, publicRoutes } from "./routes";
import ReactGA from "react-ga4";
const UnauthorizedPage = lazy(() => import("../pages/UnauthorizedPage"));

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
};

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
};

const AppContent: React.FC = () => {
  usePageTracking();

  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();
  const sideMenu = useAppSelector((state) => state.general.sideMenu);
  const { userDetails } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (isAuthenticated && sideMenu.length === 0 && userDetails?.id) {
      dispatch(fetchSideMenu(userDetails?.id));
    }
  }, [dispatch, isAuthenticated, sideMenu.length, userDetails]);

  const redirectUrl = isAuthenticated ? sideMenu[0]?.redirectUrl : "/login";

  return (
    <>
      <Toaster />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          {publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <PublicRoute>{createElement(route.element)}</PublicRoute>
              }
            />
          ))}

          <Route path="/" element={<Navigate replace to={redirectUrl} />} />
          <Route element={<Layout />}>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            {protectedRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ProtectedRoute allowedRoles={route.roles!}>
                    {createElement(route.element)}
                  </ProtectedRoute>
                }
              />
            ))}
            <Route path="*" element={<h2>Page not found</h2>} />
          </Route>
          <Route path="*" element={<h2>Page not found</h2>} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
