import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const SendNotificationsIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.997"
    height="7.332"
    viewBox="0 0 10.997 16.332"
  >
    <g
      id="water-drop---filled-_24x24_1x"
      data-name="water-drop---filled-(24x24)@1x"
      transform="translate(-4.999 -2)"
    >
      <path
        id="Path_3201"
        data-name="Path 3201"
        d="M10.379,2s4.139,6.2,5.314,9.437A4.61,4.61,0,0,1,16,13.11a5.424,5.424,0,0,1-2.318,4.269,5.7,5.7,0,0,1-6.792-.359A5.558,5.558,0,0,1,5,13a5.02,5.02,0,0,1,.278-1.731C6.314,8.057,10.379,2,10.379,2Z"
        transform="translate(0)"
        fill="#676e7b"
      />
      <path
        id="Path_3202"
        data-name="Path 3202"
        d="M8.041,14.3A3.2,3.2,0,0,0,8,14.814a3.009,3.009,0,0,0,2.882,2.963"
        transform="translate(-0.551 -2.259)"
        fill="#fff"
      />
    </g>
  </svg>,
  "SendNotificationsIcon"
);

export default SendNotificationsIcon;
