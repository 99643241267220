import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Avatar, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { logout } from "../features/user/userSlice";
import { persistor } from "../app/rootReducer";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  fetchInvitePeopleList,
  searchAdmin,
  searchBlogList,
  searchNgos,
  searchReportedPosts,
  searchVideos,
  setAdminsData,
  setClearData,
  setSearchItem,
} from "../features/super-admins/superAdminSlice";
import CloseIcon from "@mui/icons-material/Close";
import { setSliceSearchQuery } from "../features/general/generalSlice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
  flexDirection: "row-reverse",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`, // Adjust padding based on the icon width
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const theme = useTheme();
  const location = useLocation();
  const userDetails = useAppSelector((state) => state.user.userDetails);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [closeIcon, setCloseIcon] = useState<boolean>(false);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("authenticationToken");
    persistor.purge();
    window.location.href = "/login";
  };
  useEffect(() => {
    toast.dismiss();
  }, [location]);
  const [searchQuery, setSearchQuery] = React.useState<any>("");

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          {/* <AccountCircle /> */}
          <Avatar
            alt="avatar-logo"
            sx={{
              width: "23x",
              height: "30px",
              cursor: "pointer",
              color: "#fff",
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={handleProfileMenuOpen}
          >
            {userDetails?.firstName[0]}
            {userDetails?.lastName[0]}
          </Avatar>
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    setSearchQuery("");
    dispatch(setClearData({}));
    dispatch(setSearchItem(""));
    setCloseIcon(false);
  }, []);

  const searchItem = () => {
    if (location.pathname === "/invite-admins") {
      if (searchQuery) {
        const searchData = dispatch(searchAdmin({ item: searchQuery, id: 2 }));
        dispatch(setSearchItem(searchQuery));
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }

    if (location.pathname === "/blogs-list") {
      if (searchQuery) {
        const searchData = dispatch(searchBlogList({ item: searchQuery }));
        dispatch(setSearchItem(searchQuery));
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }

    if (location.pathname === "/ngo-groups") {
      console.log("searchQuery", searchQuery);
      if (searchQuery) {
        const searchData = dispatch(searchNgos({ item: searchQuery }));
        dispatch(setSearchItem(searchQuery));
        console.log("list", searchData);
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }
    if (location.pathname === "/reported-posts") {
      console.log("searchQuery", searchQuery);

      if (searchQuery) {
        const searchData = dispatch(searchReportedPosts({ item: searchQuery }));
        dispatch(setSearchItem(searchQuery));
        console.log("list", searchData);
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }
    if (location.pathname === "/video-requests") {
      console.log("searchQuery", searchQuery);
      if (searchQuery) {
        const searchData = dispatch(searchVideos({ item: searchQuery }));
        dispatch(setSearchItem(searchQuery));
        console.log("list", searchData);
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }

    if (location.pathname === "/invite-blog-writer") {
      if (searchQuery) {
        const searchData = dispatch(searchAdmin({ item: searchQuery, id: 3 }));
        dispatch(setSearchItem(searchQuery));
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }

    if (location.pathname === "/invite-video-editors") {
      if (searchQuery) {
        const searchData = dispatch(searchAdmin({ item: searchQuery, id: 4 }));
        dispatch(setSearchItem(searchQuery));
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }
    if (location.pathname === "/invite-content-moderator") {
      if (searchQuery) {
        const searchData = dispatch(searchAdmin({ item: searchQuery, id: 5 }));
        dispatch(setSearchItem(searchQuery));
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }

    if (location.pathname === "/invite-support") {
      if (searchQuery) {
        const searchData = dispatch(searchAdmin({ item: searchQuery, id: 6 }));
        dispatch(setSearchItem(searchQuery));
        searchData.then((data) => {
          if (data) {
            let res = dispatch(setAdminsData(data.payload));
            if (res) {
              setCloseIcon(true);
            }
          }
        });
      }
    }
  };

  const blogSearchQuery = localStorage.getItem("blogSearchQuery");
  useEffect(() => {
    if (blogSearchQuery) {
      setSearchQuery(blogSearchQuery);
      const searchData = dispatch(searchBlogList({ item: blogSearchQuery }));
      dispatch(setSearchItem(blogSearchQuery));
      searchData.then((data) => {
        if (data) {
          let res = dispatch(setAdminsData(data.payload));
          if (res) {
            setCloseIcon(true);
            localStorage.removeItem("blogSearchQuery");
          }
        }
      });
    }
  }, [blogSearchQuery]);

  const removeSearch = () => {
    setSearchQuery("");
    setCloseIcon(!closeIcon);
    dispatch(setClearData({}));
    dispatch(setSearchItem(""));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <style>
        {`.MuiInputBase-root.MuiInputBase-colorPrimary.css-1kcggdq-MuiInputBase-root {
            background:#f2f2fa;
            border-radius:35px;
          }
        `}
        {`input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input {
              color: black;
          }`}

        {`span.input-group-text {
            border-radius:0 0 0 0;  
             
          }`}
        {`.form-control:focus {
          border-color: #dee2e6;
          outline: none;
          box-shadow: none !important;
        }`}
      </style>
      <Box
        position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <div className="col-md-8  ">
            {location.pathname !== "/send-notifications" &&
              location.pathname !== "/create-blog" &&
              !location.pathname.includes("/edit-blog") && (
                <>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search......"
                      aria-label="Recipient's username"
                      value={searchQuery}
                      onChange={(event) => {
                        setSearchQuery(event.target.value);
                        dispatch(setSliceSearchQuery(event.target.value));
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          searchItem();
                        }
                      }}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text ">
                        {!closeIcon && (
                          <SearchIcon
                            sx={{ color: "#BFBFBF", cursor: "pointer" }}
                            onClick={() => searchItem()}
                          />
                        )}
                        {closeIcon && (
                          <CloseIcon
                            sx={{ color: "#BFBFBF", cursor: "pointer" }}
                            onClick={() => removeSearch()}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </>
              )}
          </div>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Avatar
              className="m-2 my-3"
              alt="avatar-logo"
              sx={{
                width: "42px",
                height: "42px",
                cursor: "pointer",
                color: "#fff",
                backgroundColor: theme.palette.primary.main,
              }}
              onClick={handleProfileMenuOpen}
            >
              {userDetails?.firstName[0]}
              {userDetails?.lastName[0]}
            </Avatar>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon sx={{ color: "#000" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </Box>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
