import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const BlogWritersIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.336"
    height="18.336"
    viewBox="0 0 18.336 18.336"
  >
    <g id="globe" transform="translate(-4 -4)">
      <path
        id="Path_64"
        data-name="Path 64"
        d="M22.336,13.168A9.168,9.168,0,1,1,13.168,4,9.168,9.168,0,0,1,22.336,13.168Z"
        fill="#798498"
      />
      <path
        id="Path_65"
        data-name="Path 65"
        d="M13.063,17.985a1.4,1.4,0,0,1-.1.942,2.529,2.529,0,0,1-.419.94,2.167,2.167,0,0,1-.627.42,7.426,7.426,0,0,0-.837.942c0,.1-.419.635-.523.635l-.114.058a9.1,9.1,0,0,1-1.759-.765l-.012-.033c0-.1-.1-.733-.1-.837-.1-.315-.21-.733-.315-1.047a4.65,4.65,0,0,1-.627-1.674,2.307,2.307,0,0,0-.21-1.465c-.419-.523-1.256-.523-1.674-.837a4.76,4.76,0,0,1-1.569-1.674A1.964,1.964,0,0,1,4,13.239c0-.024,0-.047,0-.071A9.127,9.127,0,0,1,6.543,6.842,4.51,4.51,0,0,1,10.1,6.134c.314.1,1.442.778,1.442,1.091,0,.1,0,.1-.1.21a.381.381,0,0,1-.314.1,4.617,4.617,0,0,1-1.407-.333C9.086,7,8.146,8.358,8.041,8.777c-.1.315-.1,1.047.419,1.152a12.892,12.892,0,0,0,1.959-1.322,1.691,1.691,0,0,1,1.694,1.348c.314.837-1.455,1.646-2.084,1.857-.208.1-.313-.1-.523,0a1.463,1.463,0,0,0-.893,1.3c-.053.529-.1,1.046-.208,1.569-.1,0-.32,0-.32-.106v-.208c0-.316-.1-.734-.419-.839-.1-.1-.253.062-.358.062-.314,0-.627-.315-.94-.1a1.285,1.285,0,0,0-.419.837.385.385,0,0,0,.1.314c.21.1.419-.1.629,0,.208,0,.208.21.208.315.21.314.419.732.733.837H9.715a3.129,3.129,0,0,1,1.047.419c.1.208.1.419.208.627a3.8,3.8,0,0,0,1.779.733C12.958,17.671,13.063,17.774,13.063,17.985ZM18.909,6.026a1.186,1.186,0,0,0-.138.046c-.314.1-1.132,1.616-1.341,1.929-.1.1-.208.314-.1.419,0,.1.1.1.21.1.208.1.237.149.446.252.1,0,.208.1.1.21a.1.1,0,0,1-.1.1c-.523.523-1.047.942-1.569,1.464a.731.731,0,0,0-.176.419c0,.1.07.1.07.21s-.1.1-.21.208a2.34,2.34,0,0,0-.523.315c-.1.208,0,.523-.1.732a8.959,8.959,0,0,1-.629,1.465c-.21.314-.4.627-.608.942,0,.419-.1.732.1,1.045.523.733,1.571.032,2.3.345.208.1.387.124.523.314a5.425,5.425,0,0,1,.4,1.436,5.754,5.754,0,0,0,.419,1.256c.1.519.309,1.037.415,1.452a9.156,9.156,0,0,0,.513-14.668Zm-3.629.43a1.8,1.8,0,0,0,.625-.9c.1-.2.416-.6.312-.9-.081-.156-.411-.249-.652-.331A9.167,9.167,0,0,0,13.168,4c-.211,0-.418.017-.625.032a.571.571,0,0,0-.382.327c-.1.5.312.4.727.5,0,0,.1.8.1.9.1.5-.209.8-.209,1.3,0,.3,0,.8.209,1h.1c.1,0,.207-.1.416-.2.312-.2.625-.5.936-.7A5.942,5.942,0,0,0,15.28,6.456Z"
        fill="#676e7b"
      />
    </g>
  </svg>,
  "BlogWritersIcon"
);

export default BlogWritersIcon;
