import React from "react";
import { createSvgIcon } from "@mui/material/utils";

const VideoRequest = createSvgIcon(
  <svg
    id="video-upload-fill-svgrepo-com_2_"
    data-name="video-upload-fill-svgrepo-com (2)"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_16300"
      data-name="Path 16300"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <path
      id="Path_16301"
      data-name="Path 16301"
      d="M16,4a1,1,0,0,1,1,1V9.2l5.213-3.65A.5.5,0,0,1,23,5.96V18.04a.5.5,0,0,1-.5.5.506.506,0,0,1-.287-.09L17,14.8V19a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V5A1,1,0,0,1,2,4ZM9,8,5,12H8v4h2V12h3Z"
      fill="#676e7b"
    />
  </svg>,
  "VideoRequest"
);

export default VideoRequest;
