import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import { toast } from "react-hot-toast";
import { StatusOption } from "./statusEnum";
interface RoleType {
  id: number | null;
  name: string;
  status: number | null;
}
interface InviteMember {
  emails: any[];
  message: string;
  type: string;
}
interface superAdmin {
  inviteMember: InviteMember;
  searchData: any;
  searchItem: any;
  loading: boolean;
  adminsData: any;
  videosData: any;
  blogWritersData: any;
  supportData: any;
  contentModerators: any;
  videoDatas: any;
}

const initialState: superAdmin = {
  inviteMember: {
    emails: [],
    message: "",
    type: "",
  },
  searchData: {},
  searchItem: "",
  loading: false,
  adminsData: {},
  videosData: {},
  blogWritersData: {},
  supportData: {},
  contentModerators: {},
  videoDatas: {},
};

const rolesConfig: Record<string, { url: string; role: RoleType }> = {
  super_admin: {
    url: "/invite-super-admin",
    role: { id: 1, name: "SUPER ADMIN", status: 1 },
  },
  admin: {
    url: "/invite-admin",
    role: { id: 2, name: "ADMIN", status: 1 },
  },
  blog_writer: {
    url: "/invite-blog-writer",
    role: { id: 3, name: "BLOG WRITER", status: 1 },
  },
  video_editor: {
    url: "/invite-video-editor",
    role: { id: 4, name: "VIDEO EDITOR", status: 1 },
  },
  content_moderator: {
    url: "/invite-content-moderator",
    role: { id: 5, name: "CONTENT MODERATOR", status: 1 },
  },
  support: {
    url: "/invite-support",
    role: { id: 6, name: "VERIFICATION SUPPORT", status: 1 },
  },
};

export const inviteMemberData = createAsyncThunk(
  "inviteMember/inviteMemberData",
  async (values: InviteMember, { rejectWithValue }) => {
    const config = rolesConfig[values.type] || rolesConfig["admin"];
    const payload = {
      emailAddresses: values.emails,
      message: values.message,
      role: config.role,
    };

    try {
      const response = await axiosInstance.post(config.url, payload);
      toast.success(response?.data?.message, {
        duration: 6000,
      });
      return response.data;
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchInvitePeopleList = createAsyncThunk(
  "general/fetchInvitePeopleList",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const response = await axiosInstance.get(
        `/admins?page=${page}&size=15&sort=invitationDate,desc`
      );
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchVideoRequestList = createAsyncThunk(
  "general/fetchVideoRequestList",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const response = await axiosInstance.get(
        `/activities/basic-info?page=${page}&size=15&sort=created_at,desc`
      );
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const handleFileDownload = createAsyncThunk(
  "general/handleFileDownload",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/activity/download-media-files?activityId=${id}`,
        {
          responseType: "blob", // This ensures the response is treated as a Blob
        }
      );
      console.log("my download", response);
      return URL.createObjectURL(response.data);
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBlogWriters = createAsyncThunk(
  "general/fetchBlogWriters",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const response = await axiosInstance.get(
        `/blog-writers?page=${page}&size=10&sort=invitationDate,desc`
      );
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchInvitersList = createAsyncThunk(
  "general/fetchInvitersList",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const response = await axiosInstance.get(
        `/video-editors?page=${page}&size=10&sort=invitationDate,desc`
      );
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchSupports = createAsyncThunk(
  "general/verification-supports",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const response = await axiosInstance.get(
        `/verification-supports?page=${page}&size=10&sort=invitationDate,desc`
      );
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchContentModerators = createAsyncThunk(
  "general/content-moderators",
  async (currentPage: number, { rejectWithValue }) => {
    const page = currentPage - 1;
    try {
      const response = await axiosInstance.get(
        `/content-moderators?page=${page}&size=10&sort=invitationDate,desc`
      );
      return response.data?.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBlogSingle = createAsyncThunk(
  "general/fetchBlogSingle",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/blogs-details/${id}`);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchdeleteAdmin = createAsyncThunk<
  any,
  { id: number },
  { rejectValue: any }
>("general/fetchdeleteAdmin", async (id, { rejectWithValue }) => {
  try {
    console.log("mine", id);
    const response = await axiosInstance.delete(`/admins/${id}`);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      return rejectWithValue({
        name: error.name,
        message: "Network error or bad request, no response object.",
      });
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchdeleteBlogList = createAsyncThunk<
  any,
  { id: number },
  { rejectValue: any }
>("general/fetchdeleteAdmin", async (id, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.delete(`/blogs/${id}`);
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      return rejectWithValue({
        name: error.name,
        message: "Network error or bad request, no response object.",
      });
    }
    return rejectWithValue(error.response.data);
  }
});

export const fetchStatusPosts = createAsyncThunk<
  any,
  {
    data: {
      SNo: any;
    };
    statusOption: StatusOption;
    reportedPostType: any;
  },
  { rejectValue: any }
>(
  "general/fetchStatusPosts",
  async ({ data, statusOption, reportedPostType }, { rejectWithValue }) => {
    try {
      console.log("mine", data);
      const body = { status: statusOption };
      console.log("body", body);
      const response = await axiosInstance.put(
        `/reported-posts/${data?.SNo}/status/${statusOption}?reportedPostType=${reportedPostType}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchEscaletedPosts = createAsyncThunk<
  any,
  {
    data: {
      SNo: any;
    };
    statusOption: StatusOption;
    reportedPostType: any;
  },
  { rejectValue: any }
>(
  "general/fetchEscaletedPosts",
  async ({ data, statusOption, reportedPostType }, { rejectWithValue }) => {
    try {
      console.log("mine", data);
      const body = { status: statusOption };
      console.log("body", body);
      const response = await axiosInstance.put(
        `/reported-activities/${data?.SNo}/escalate?reportedPostType=${reportedPostType}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBannedPosts = createAsyncThunk<
  any,
  {
    data: {
      userID: any;
    };
    statusOption: StatusOption;
    reportedPostType: any;
    activityId: any;
  },
  { rejectValue: any }
>(
  "general/fetchBannedPosts",
  async (
    { data, statusOption, reportedPostType, activityId },
    { rejectWithValue }
  ) => {
    try {
      console.log("mine", data);
      const body = { status: statusOption };
      console.log("body", body);
      const response = await axiosInstance.put(
        `/ban-user/${data?.userID}?activityId=${activityId}&ReportedPostType=${reportedPostType}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const fetchUnBannedPosts = createAsyncThunk<
  any,
  {
    data: {
      SNo: any;
    };
    // statusOption: StatusOption;
    reportedPostType: any;
    activityReporterId: any;
  },
  { rejectValue: any }
>(
  "general/fetchUnBannedPosts",
  async (
    { data, reportedPostType, activityReporterId },
    { rejectWithValue }
  ) => {
    try {
      console.log("mine", data);
      const response = await axiosInstance.put(
        `/unban-user/${data?.SNo}?activityReporterId=${activityReporterId}&ReportedPostType=${reportedPostType}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const editAdmin = createAsyncThunk(
  "superAdmin/editAdmin",
  async ({ id, data }: { id: number; data: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/admins/${id}`, data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "superAdmin/deleteVideo",
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/activity/remove-media-files?activityId=${id}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishVideo = createAsyncThunk(
  "superAdmin/publishVideo",
  async ({ id }: { id: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/activity/publish-media-files?activityId=${id}`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchAdmin = createAsyncThunk(
  "superAdmin/searchAdmin",
  async ({ item, id }: { item: any; id: any }, { rejectWithValue }) => {
    console.log("accept", item);
    try {
      const response = await axiosInstance.get(
        `/admins/search?keyword=${item}&roleId=${id}&page=0&size=150`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchBlogList = createAsyncThunk(
  "superAdmin/searchBlogList",
  async ({ item }: { item: any }, { rejectWithValue }) => {
    console.log("accept", item);
    try {
      const response = await axiosInstance.get(
        `admin-blogs/search?keyword=${item}&page=0&size=100&sort=Id`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchNgos = createAsyncThunk(
  "superAdmin/searchNgos",
  async ({ item }: { item: any }, { rejectWithValue }) => {
    console.log("accept", item);
    try {
      const response = await axiosInstance.get(
        `organizations/search?keyword=${item}&page=0&size=100&sort=Id`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchVideos = createAsyncThunk(
  "superAdmin/searchVideos",
  async ({ item }: { item: any }, { rejectWithValue }) => {
    console.log("accept", item);
    try {
      const response = await axiosInstance.get(
        `activities/basic-info/search?keyword=${item}&page=0&size=100&sort=Id`
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchReportedPosts = createAsyncThunk(
  "superAdmin/searchReportedPosts",
  async ({ item }: { item: any }, { rejectWithValue }) => {
    console.log("accept", item);
    try {
      const body = { searchString: item };
      const response = await axiosInstance.post(
        `reported-posts/search?page=0&size=30&sort=Id`,
        body
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const uploadVideo = createAsyncThunk(
  "superAdmin/uploadVideo",
  async ({ data }: { data: any }, { rejectWithValue }) => {
    console.log("accept", data);
    try {
      const response = await axiosInstance.post(
        `/activity/upload-media-files`,
        data
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        return rejectWithValue({
          name: error.name,
          message: "Network error or bad request, no response object.",
        });
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const superAdminSlice = createSlice({
  name: "superAdmin",
  initialState,
  reducers: {
    setInviteMember(state, action: PayloadAction<InviteMember>) {
      state.inviteMember = action.payload;
    },
    setAdminsData(state, action) {
      console.log("i enter into search again");
      state.searchData = action.payload.data;
      if (action.payload.data.content?.length === 0) {
        toast.error("No search results found");
      }
    },
    setClearData(state, action) {
      state.searchData = {};
      console.log("yup i enter into", action.payload);
    },
    setSearchItem(state, action: PayloadAction<string>) {
      state.searchItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(inviteMemberData.fulfilled, (state, action) => {
        state.inviteMember = action.payload;
        state.loading = false;
      })
      .addCase(inviteMemberData.pending, (state) => {
        state.loading = true;
      })
      .addCase(inviteMemberData.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(searchNgos.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchNgos.fulfilled, (state, action) => {
        // Update the search item when searchNgos is fulfilled
        state.searchItem = action.meta.arg.item;
        state.loading = false;
      })
      .addCase(searchNgos.rejected, (state, action) => {
        state.loading = false;
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(fetchInvitePeopleList.pending, (state) => {})
      .addCase(fetchInvitePeopleList.fulfilled, (state, action) => {
        state.adminsData = action.payload;
      })
      .addCase(fetchInvitePeopleList.rejected, (state, action) => {
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(fetchVideoRequestList.pending, (state) => {})
      .addCase(fetchVideoRequestList.fulfilled, (state, action) => {
        state.videosData = action.payload;
      })
      .addCase(fetchVideoRequestList.rejected, (state, action) => {
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(fetchBlogWriters.pending, (state) => {})
      .addCase(fetchBlogWriters.fulfilled, (state, action) => {
        state.blogWritersData = action.payload;
      })
      .addCase(fetchBlogWriters.rejected, (state, action) => {
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(fetchSupports.pending, (state) => {})
      .addCase(fetchSupports.fulfilled, (state, action) => {
        state.supportData = action.payload;
      })
      .addCase(fetchSupports.rejected, (state, action) => {
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(fetchContentModerators.pending, (state) => {})
      .addCase(fetchContentModerators.fulfilled, (state, action) => {
        state.contentModerators = action.payload;
      })
      .addCase(fetchContentModerators.rejected, (state, action) => {
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(fetchInvitersList.pending, (state) => {})
      .addCase(fetchInvitersList.fulfilled, (state, action) => {
        state.videoDatas = action.payload;
      })
      .addCase(fetchInvitersList.rejected, (state, action) => {
        toast.error("Network error or bad request, no response object.");
      })
      .addCase(editAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(editAdmin.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.adminsData?.content.findIndex(
          (admin: any) => admin.id === action.meta.arg?.data?.id
        );
        if (index !== -1) {
          console.log("romalPayload", action.meta.arg?.data);
          state.adminsData.content[index] = action.meta.arg?.data;
        }
        toast.success("Admin updated successfully");
      })
      .addCase(editAdmin.rejected, (state, action) => {
        state.loading = false;
        toast.error("Failed to update admin");
      });
  },
});
export const { setInviteMember, setAdminsData, setClearData, setSearchItem } =
  superAdminSlice.actions;

export default superAdminSlice.reducer;
