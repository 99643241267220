import React, { FC, ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import UnauthorizedPage from "../pages/UnauthorizedPage";

export enum UserRole {
  SUPER_ADMIN = "SUPER ADMIN",
  ADMIN = "ADMIN",
  VERIFICATION_SUPPORT = "VERIFICATION SUPPORT",
  BLOG_WRITER = "BLOG WRITER",
  VIDEO_EDITOR = "VIDEO EDITOR",
  CONTENT_MODERATOR = "CONTENT MODERATOR",
  USER = "USER",
}
interface ProtectedRouteProps {
  allowedRoles: UserRole[];
  children: ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  allowedRoles,
  children,
}) => {
  const { isAuthenticated, userRole } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    const loginPath = `/login?next=${location.pathname}`;
    return <Navigate to={loginPath} replace />;
  }

  if (!allowedRoles.includes(userRole as UserRole)) {
    return <UnauthorizedPage />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
