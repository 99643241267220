import axios from "axios";

const TIMEOUT = 1 * 60 * 1000;

console.log(process.env.REACT_APP_BASE_URL, "=======baseeeee");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: TIMEOUT,
});

const setupAxiosInterceptors = (onUnauthenticated: () => void) => {
  const onRequestSuccess = (config: any) => {
    const token = localStorage.getItem("authenticationToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

  const onResponseSuccess = (response: any) => response;

  const onResponseError = (err: any) => {
    const status = err.response ? err.response.status : 0;
    if (status === 401) {
      onUnauthenticated();
    }
    return Promise.reject(err);
  };

  axiosInstance.interceptors.request.use(onRequestSuccess);
  axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
};

export { axiosInstance, setupAxiosInterceptors };
